import React, { useState } from "react";
import Content from "./Content";
import { List, SideBar, SearchLink, Word } from "../common/Styled";

export default function ReferencesContainer({ references }) {
  const [activeReference, setActiveReference] = useState(references[0]);

  return (
    <>
      <SideBar width="210">
        <List>
          {references.map((word) => (
            <Word key={word.name}>
              <SearchLink
                href="#"
                role="button"
                onClick={() => setActiveReference(word)}
                className={word.name === activeReference.name ? "active" : ""}
              >
                {word.name}
              </SearchLink>
            </Word>
          ))}
        </List>
      </SideBar>
      <Content activeWord={activeReference.content}></Content>
    </>
  );
}
