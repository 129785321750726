import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { WordsListContainer } from "../components/common/WordListContainer";
import { FlexColumn } from "../components/common/Styled";
import ReferencesContainer from "../components/references/ReferencesContainer";

export default function ReferencesPage({ data, pageContext }) {
  return (
    <FlexColumn>
      <SEO title={`References`} />
      <WordsListContainer>
        <ReferencesContainer references={data.references.references} />
      </WordsListContainer>
    </FlexColumn>
  );
}

export const query = graphql`
  query {
    references: LOOWIAG {
      references {
        name
        content
      }
    }
  }
`;
